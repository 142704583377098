function hotelService(id,callback,file="hotels.json") {
    return function (dispatch) {
      return fetch(`/JSON-SOURCE/${file}`)
        .then(data => data.json(), {cache: "no-cache"})
        .then(data => {
            dispatch(callback(id,data))
        })
        .catch((error) => {
            dispatch(callback(id,[],error))
        });
     
    }
  }

  export {hotelService}