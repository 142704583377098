const firebaseConfig = {
  apiKey: "AIzaSyB_A6ltv76lfkEIjn8Ziqyfnv-foUxMsMA",
  authDomain: "gem-guest-capture.firebaseapp.com",
  databaseURL: "https://gem-guest-capture.firebaseio.com",
  projectId: "gem-guest-capture",
  storageBucket: "gem-guest-capture.appspot.com",
  messagingSenderId: "435597242718",
  appId: "1:435597242718:web:e1a616ae0144c246959fcf"
};

  export default firebaseConfig;