import React, { useEffect } from 'react'
import { makeStyles,withStyles ,useTheme } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {Divider,Button,Backdrop,FormControl,FormLabel,RadioGroup,FormControlLabel,Radio,Checkbox} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {useFirebase} from '../Firebase'
import CircularProgress from '@material-ui/core/CircularProgress';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import * as reqTypes from '../../redux/actionType'
import storage from '../../storage';
import Login from './Login'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {auth,firestore} from '../Firebase'
import PhoneIcon from '@material-ui/icons/Phone';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import moment from 'moment'
import PrintIcon from '@material-ui/icons/Print';
import SyncIcon from '@material-ui/icons/Sync';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
const useStyles = makeStyles((theme) => ({
    paperContainer:{
        position:'relative',
        width:'100%',
        display:'flex',
        justifyContent:'center',
        paddingBottom:'100px'
    },   
    iconText:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        marginRight:'0.5rem'
    },
    paper: {
       padding:"1rem",
       margin:'1rem',
       width:'100%'
    },
    input: {
        marginBottom:'0.75rem',
        '& .MuiInputLabel-formControl[data-shrink="false"]': {
            textOverflow: 'ellipsis !important',
            fontSize:'0.85rem'
          }
      },
      divider:{
          width:'100%',
          margin:'1rem'
      },
      button:{
          width:'100%',
          marginTop:'2rem',
          marginBottom:'2rem'

      },
      backdrop:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        zIndex:1
      },
      confirmationBox:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        flexDirection:'column',
        padding:'5rem'
      },
      profileDetails:{
        width:'100%'
      }
}));
const UserList=({sync})=>{
    const state = useSelector(state => state.general); 
    const classes=useStyles();
    const [checkState, setCheckState] = React.useState({
      optedIn: false
    });
  
    const exportToExcel=()=>{
      var htmls = "";
                  var uri = 'data:application/vnd.ms-excel;base64,';
                  var template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'; 
                  var base64 = function(s) {
                      return window.btoa(unescape(encodeURIComponent(s)))
                  };
      
                  var format = function(s, c) {
                      return s.replace(/{(\w+)}/g, function(m, p) {
                          return c[p];
                      })
                  };
      
                  htmls = document.getElementById("tbl_print").outerHTML
      
                  var ctx = {
                      worksheet : 'Worksheet',
                      table : htmls
                  }
      
      
                  var link = document.createElement("a");
                  link.download = "Registered-Guests.xls";
                  link.href = uri + base64(format(template, ctx));
                  link.click();
      }
    const printView=()=>{
           let w=window.open();
            w.document.write(document.getElementById("tbl_print").outerHTML);
            w.print();
            w.close();
    }
    const refresh=()=>{
        sync();
    }
    const handleChange=(event)=>{
      setCheckState({ ...checkState, [event.target.name]: event.target.checked });
    }
    let userList=state.userList||[];
    if(checkState.optedIn && userList.length>0){
      userList=userList.filter(p=>p.promotions);
    }
    return ( <div  className={classes.paperContainer}>
        <Paper elevation={3} className={classes.paper} >
           <Box display="flex" justifyContent="center" flexDirection="column">
            <Box display="flex" alignItems="center"  flexDirection="row">
                <Typography variant="h5" component="h2" style={{flexGrow:1}}>
                    Registered Guests  
                    {/* <FormControlLabel
        control={<Checkbox  color="primary" checked={checkState.optedIn} onChange={handleChange} name="optedIn" />}
        label="Opted-in"
      /> */}
                </Typography>
                <Box  displayPrint="none" component="span" style={{cursor:'pointer',marginRight:'1rem'}} onClick={e=>refresh()}><SyncIcon></SyncIcon></Box>
                <Box  displayPrint="none" component="span" style={{cursor:'pointer',marginRight:'1rem'}}  onClick={e=>printView()}><PrintIcon></PrintIcon></Box>
                <Box  displayPrint="none" component="span" style={{cursor:'pointer',marginRight:'1rem'}}  onClick={e=>exportToExcel()}><CloudDownloadIcon></CloudDownloadIcon></Box>
            </Box>
            <Divider variant="middle" className={classes.divider} />
           </Box>
           <TableContainer component={Paper} id="tbl_print">
           <Table  aria-label="" size="small" style={{border:'1px solid #DEDEDE',borderCollapse:'collapse'}}>
             <TableHead>
               <TableRow>
                 <TableCell  align="left" style={{textAlign:'left',border:'1px solid #DEDEDE',padding:'0.5rem',fontWeight:'bold'}}>Name</TableCell>
                 <TableCell align="left"  style={{textAlign:'left',border:'1px solid #DEDEDE',padding:'0.5rem',fontWeight:'bold'}}>Email</TableCell>
                 <TableCell align="left"  style={{textAlign:'left',border:'1px solid #DEDEDE',padding:'0.5rem',fontWeight:'bold'}}>Phone</TableCell>
                 <TableCell align="left"  style={{textAlign:'left',border:'1px solid #DEDEDE',padding:'0.5rem',fontWeight:'bold'}}>Hotel</TableCell>
                 <TableCell align="left"  style={{textAlign:'left',border:'1px solid #DEDEDE',padding:'0.5rem',fontWeight:'bold'}}>Purpose</TableCell>
                 <TableCell align="left"  style={{textAlign:'left',border:'1px solid #DEDEDE',padding:'0.5rem',fontWeight:'bold'}}>Address</TableCell>
                 <TableCell align="left"  style={{textAlign:'left',border:'1px solid #DEDEDE',padding:'0.5rem',fontWeight:'bold'}}>Last Visit</TableCell>
               </TableRow>
             </TableHead>
             <TableBody>
               {userList.map((row,idx) => (
                 <TableRow key={idx}>
                   <TableCell style={{textAlign:'left',border:'1px solid #DEDEDE',padding:'0.5rem'}} align="left" component="th" scope="row">
                   {row.name}  {row.surname}
                   </TableCell>
                   <TableCell style={{textAlign:'left',border:'1px solid #DEDEDE',padding:'0.5rem'}} align="left">{row.email}</TableCell>
                   <TableCell style={{textAlign:'left',border:'1px solid #DEDEDE',padding:'0.5rem'}} align="left">{row.phone}</TableCell>
                   <TableCell style={{textAlign:'left',border:'1px solid #DEDEDE',padding:'0.5rem'}} align="left">{row.hotel_id}</TableCell>
                   <TableCell style={{textAlign:'left',border:'1px solid #DEDEDE',padding:'0.5rem'}} align="left">{row.purpose}</TableCell>
               <TableCell style={{textAlign:'left',border:'1px solid #DEDEDE',padding:'0.5rem'}} align="left">{row.address},{row.city},{row.postcode}</TableCell>
               <TableCell align="left" style={{textAlign:'left',border:'1px solid #DEDEDE',padding:'0.5rem'}} align="left">{moment.utc(new Date(row.modified.seconds*1000)).local().format("DD MMM YYYY HH:mm:ss")}</TableCell>
                 </TableRow>
               ))}
             </TableBody>
           </Table>
         </TableContainer>
            </Paper>
        </div>
       );
}
export default  (props)=>{
    const state = useSelector(state => state.general); 
    const dispatch=useDispatch();
   
    useEffect(()=>{
        async function fetchData() {
            if(state.authUser && !state.isLoading){
               await fetchUsers();
            }    
        }
        fetchData();
    },[state.authUser]);
    const fetchUsers=async()=>{
        if(!state.isLoading){
           dispatch({ type: reqTypes.SET_LOADER, payload: true });
            const firstx =firestore.collection("users").orderBy('modified',"desc");
            const snapshotx = await firstx.get();
            let source=snapshotx.docs.map(doc => doc.data());
         
            dispatch({ type: reqTypes.SET_USER_LIST, payload: source });
        }
    }
    if(state.authUser==null)return <Login></Login>;
    return <React.Fragment>
        <UserList sync={fetchUsers}></UserList>
    </React.Fragment>;
   
}