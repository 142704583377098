import { SET_GEN_INFO ,SET_LOADER,SET_AUTH,SET_USER_LIST,SET_CONFIG_DATA,SET_HOTEL} from "../actionType";
const initialState = { 
   userData:null,
   isLoading:true,
   authUser:null,
   isSuccess:false,
   errorMessage:'',
   activeHotel:null,
   userList:[],
   configData:{
     message:''
   }
};

function reducer(state = initialState, action) {
    switch (action.type) {
      case "reset":
        return initialState;
        case SET_HOTEL:
     return {
            ...state,
            activeHotel: action.payload.hotel
          };
      case SET_GEN_INFO:
        return {
          ...state,
          userData: action.payload?action.payload.data:null,
          isLoading:false
        };
        case SET_LOADER:
        return {
          ...state,
          isLoading: action.payload
        };
        case SET_AUTH:
          return {
            ...state,
            ...action.payload,
            isLoading:false
          };
          case SET_USER_LIST:
            return {
              ...state,
              userList:action.payload,
              isLoading:false
            };
            case SET_CONFIG_DATA:
              return {
                ...state,
                configData:action.payload,
                isLoading:false
              };
      default:
        return state;
    }
  }

  export {reducer as default}
