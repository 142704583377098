
class AppStorage
{   
    constructor(){
        this.___store_key="e_f_d";
    }
    get isData(){
      
        let val=localStorage.getItem(this.___store_key);
        return val&&val.trim().length>0;
    }
    get keyData(){
        let val=localStorage.getItem(this.___store_key);
        return val;
    }
    set isInit(val=''){
        localStorage.setItem(this.___store_key,val);
    }
}
export default new AppStorage() ;