import React, { useState, useEffect } from 'react';
import * as reqTypes from './redux/actionType'
import {
  HashRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useHistory
} from "react-router-dom";
import Layout from './components/Layout'
import Home from './components/Home'
import Administration from './components/Administration'
import { useDispatch, useSelector } from "react-redux";
import Login from './components/Administration/Login';
import Thankyou from './components/Home/Thankyou';
import { hotelService } from './services'

const App = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const history = useHistory();
  const { hotelCode } = useParams();
  useEffect(() => {
    let hotel_id = hotelCode || '';
    let paths=history.location.pathname.split("/");
    if(paths.length>1)hotel_id=paths[1];
    dispatch(hotelService(hotel_id, (id, hotels, error) => {
      if (error == undefined || error == null) {
        hotels = hotels || [];
        let hotel = hotels.find(p => p.id.toLowerCase() === id.toLowerCase());
        return { type: reqTypes.SET_HOTEL, payload: { hotel } }
      }
      else {
        return { type: reqTypes.SET_HOTEL, payload: { hotel: {} } }
      }
    }));
  }, []);
  return <Layout>
    <Route exact path='/:hotelCode?' component={Home} />
    <Route exact path='/admin/reports' component={Administration} />
  </Layout>
};
export default App;
